
import React from "react";
import "./avatar.scss";

import {StringWidth} from "Functions";
import gsap, {CSSPlugin, Power2, TweenLite} from "gsap";

import * as Src from "./Library";
import LoadImage from "Components/Layout/LoadImage";
import Pattern from "./bubble.png";

gsap.registerPlugin(CSSPlugin);

class Avatar extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Bubbles = 0;
        this.CenterText = 0;
        this.Duration = 1.5;
        this.Ease = Power2.easeOut;
        this.Image = false;
        this.MaxFontSize = 46;
        this.Name = false;
        this.Offset = 20;
        this.Text = "";

        this.state =
        {
            fontSize: 10,
            loaded: false,
            text: []
        };
    }

    componentDidMount()
    {
        const {text} = this.props;

        this.SetText(text);
    }

    componentDidUpdate()
    {
        let {text} = this.props;

        if (!text)
        {
            text = "Nu gick det nog för snabbt...";
        }

        if (text !== this.Text)
        {
            this.SetText(text);
        }
    }

    AvatarImage = (key, index = 0) =>
    {
        return (
            <LoadImage
                {...this.props}

                autoAdjust={true}
                className="AvatarImage"
                onLoad={index ? undefined : this.OnLoad}
                key={key}
                src={Src[key]}
            />
        );
    }

    OnBubble = (bubble) =>
    {
        if (!bubble)
        {
            return;
        }

        const Pattern = bubble.getElementById("patternImage" + this.Bubbles);

        TweenLite.from(bubble, this.Duration, {
            y: this.Offset,
            opacity: 0,
            ease: this.Ease
        });

        TweenLite.from(Pattern, this.Duration, {
            attr: {y: this.Offset * -1.5},
            ease: this.Ease
        });

        this.Bubbles++;
    }

    OnLoad = (src) =>
    {
        if (this.Image && this.Name)
        {
            TweenLite.fromTo([this.Image, this.Name], this.Duration, {
                y: this.Offset * 2,
                opacity: 0
            }, {
                y: 0,
                opacity: 1,
                ease: this.Ease
            });
        }

        this.setState({loaded: !!src});
    }

    SetText = (text) =>
    {
        if (this.props.textImage)
        {
            return;
        }

        this.Text = text;

        if (text)
        {
            const TextWidth = StringWidth(text, 20, "Gravity");
            const FontSize = Math.min(Math.round(1150 / Math.sqrt(TextWidth)), this.MaxFontSize);
            const Text = [];
            const SetText = [];
            const Words = text.split(" ");
            let Sentence = [[], 0, 0, 0];

            // Distribute the words into rows. Measure the length of each
            // row, first word and last word in order average row lengths
            // in the next step.
            Words.forEach(word => {
                const WordWidth = StringWidth(word);
                const NextWidth = StringWidth(Sentence[0].join(" ") + " " + word, FontSize);

                if (!Sentence[0].length)
                {
                    Sentence[1] = Sentence[2] = Sentence[3] = WordWidth;
                }
                else if (NextWidth > 360)
                {
                    Text.push(Sentence);
                    Sentence = [[], WordWidth, WordWidth, WordWidth];
                }
                else
                {
                    Sentence[1] = NextWidth;
                    Sentence[3] = WordWidth;
                }

                Sentence[0].push(word);
            });

            Text.push(Sentence);

            // Move words between rows to average out their length.
            for (let i = 0; i < Text.length; i++)
            {
                const A1 = i ? Text[i - 1][1] - Text[i - 1][3] : 0;
                const A2 = i ? Text[i][1] + Text[i - 1][3] : 0;
                const B1 = i < Text.length - 1 ? Text[i + 1][1] - Text[i + 1][2] : 0;
                const B2 = i < Text.length - 1 ? Text[i][1] + Text[i + 1][2] : 0;
                const D1 = A1 - A2;
                const D2 = B1 - B2;

                if (D1 > 0 && D1 >= D2)
                {
                    Text[i][0].unshift(Text[i - 1][0].pop());
                    Text[i][1] = A2;
                    Text[i][2] = Text[i - 1][3];
                    Text[i - 1][1] = Text[i - 1][3] = A1;
                }
                else if (D2 > 0 && D2 > D1)
                {
                    Text[i][0].push(Text[i + 1][0].shift());
                    Text[i][1] = B2;
                    Text[i][3] = Text[i + 1][2];
                    Text[i + 1][1] = Text[i + 1][2] = B1;
                }
            }

            Text.forEach(row => SetText.push(row[0].join(" ")));

            this.setState({
                fontSize: FontSize,
                text: SetText
            });
        }
        else
        {
            this.setState({
                fontSize: this.MaxFontSize,
                text: []
            });
        }
    }

    SpeechBubble = () =>
    {
        const {text: clearText, textImage} = this.props;
        const {fontSize, loaded, text} = this.state;

        if (!loaded)
        {
            return "";
        }

        if (textImage)
        {
            return [
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 457.95 339.8"
                    key={textImage + "bubble"}
                    ref={this.OnBubble}
                >
                    <defs>
                        <pattern id={`patternImage${this.Bubbles}`} x="0" y="0" width="85" height="85" patternUnits="userSpaceOnUse">
                            <image xlinkHref={Pattern} x="0" y="0" width="85" height="85"></image>
                        </pattern>
                    </defs>
                    <path fill={`url(#patternImage${this.Bubbles})`} d="M0,190.61l26.8-31.27c0,0-29.78-84.88,8.93-111.68s335.05-81.9,376.74-17.87s62.54,214.43,28.29,260.59s-338.02,53.61-355.89,47.65c-17.87-5.96-53.61-148.91-53.61-148.91L0,190.61z"/>
                </svg>,
                <LoadImage
                    className="AvatarTextImage"
                    key={textImage + "text"}
                    src={textImage}
                />
            ];
        }
        else if (text && text.length)
        {
            const Rows = text.length;
            const RowHeight = fontSize * 1.2;
            const HeightTotal = (Rows - 1) * RowHeight;
            const Text = [];
            let Y = 190 + HeightTotal / -2;

            text.forEach((row, index) => {
                Text.push(
                    <text
                        fontSize={fontSize * 0.8}
                        key={index}
                        textAnchor={this.CenterText ? "middle" : "left"}
                        x={this.CenterText ? "250" : "80"}
                        y={Y + index * RowHeight}
                    >{row}</text>
                )
            });

            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 457.95 339.8"
                    key={clearText}
                    ref={this.OnBubble}
                >
                    <defs>
                        <pattern id={`patternImage${this.Bubbles}`} x="0" y="0" width="85" height="85" patternUnits="userSpaceOnUse">
                            <image xlinkHref={Pattern} x="0" y="0" width="85" height="85"></image>
                        </pattern>
                    </defs>
                    <path fill={`url(#patternImage${this.Bubbles})`} d="M0,190.61l26.8-31.27c0,0-29.78-84.88,8.93-111.68s335.05-81.9,376.74-17.87s62.54,214.43,28.29,260.59s-338.02,53.61-355.89,47.65c-17.87-5.96-53.61-148.91-53.61-148.91L0,190.61z"/>
                    {Text}
                </svg>
            );
        }
    }

    render()
    {
        const {bubblePosition, className, name, src, text} = this.props;
        const {loaded} = this.state;
        const CA = ["Avatar"];
        const Images = [];

        if (loaded)
        {
            CA.push("Loaded");
        }

        if (src)
        {
            CA.push("HasSrc");
        }
        else
        {
            CA.push("NoSrc");
        }

        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");
        const [X, Y] = bubblePosition;

        if (src)
        {
            Images.push(this.AvatarImage(src));
        }
        else
        {
            let Index = 0;

            for (let s in Src)
            {
                Images.push(this.AvatarImage(s, Index));
                Index++;
            }
        }

        return (
            <div className={CS} title={text}>
                <div
                    className="AvatarImageContainer"
                    ref={i => this.Image = i}
                >
                    {Images}
                </div>
                <div
                    className="AvatarSpeechBubble"
                    style={{right: X * 100 + "%", top: Y * 100 + "%"}}
                    title={name}
                >
                    {this.SpeechBubble()}
                    <div
                        className="AvatarName"
                        key={src}
                        ref={n => this.Name = n}
                        title={name}
                    >{name}</div>
                </div>
            </div>
        );
    }
}

Avatar.defaultProps =
{
    bubblePosition: [0.05, 0.05],
    className: "",
    name: "",
    src: "ManWithDog",
    text: "",
    textImage: ""
};

export default Avatar;