
/*!
 *  Load and display an image resource.
 *
 *  @prop string className - Append a class name.
 *  @prop function onLoad - OnLoad callback. (src, width, height).
 *  @prop function onError - OnError callback. (src).
 *  @prop string src - Image source URL or image name in Media/Photos.
 *  @prop object style - Image inline styles.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./loadimage.scss";

class LoadImage extends React.Component
{
    constructor(props)
    {
        super( props );

        this.Mounted = false;
        this.Loading = false;
        this.Src = "";

        this.state =
        {
            height: 0,
            loaded: false,
            loading: false,
            src: false,
            width: 0
        };
    }

    /**
     * Load the image on mount.
     *
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;

        const {src, src2x} = this.props;
        const PR = window.devicePixelRatio;
        const Src = PR > 1 && src2x ? src2x : src;

        this.Load(Src);
    }

    /**
     * Reload the image when its' source changes.
     *
     * @return void
     */

    componentDidUpdate()
    {
        const {src, src2x} = this.props;
        const {src: currentSrc} = this.state;
        const PR = window.devicePixelRatio;
        const Src = PR > 1 && src2x ? src2x : src;

        if (Src !== currentSrc)
        {
            this.Load(Src);
        }
    }

    /**
     * Register unmount.
     *  
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     *   Load an image source.
     * 
     *   @param string src - Image source URL or image name in Media/Photos
     *
     *   @return void
     */

    Load = (src) =>
    {
        if (!src)
        {
            return;
        }

        const {onError, onLoad} = this.props;
        const Img = new Image();

        Img.onload = () =>
        {
            if (!this.Mounted)
            {
                return;
            }

            const {width, height} = Img;

            this.setState({
                height,
                loaded: true,
                loading: false,
                width
            });

            onLoad(src, width, height);
        };

        Img.onerror = () =>
        {
            if (!this.Mounted)
            {
                return;
            }

            this.setState({
                error: true,
                loading: false
            });

            onError(src);
        };

        this.setState({
            loaded: false,
            loading: true,
            src
        });

        onLoad(false);

        Img.src = src;
    }

    render()
    {
        const {autoAdjust, className, style} = this.props;
        const {height, loaded, src, width} = this.state;
        const CA = ["LoadImage"];

        if (loaded)
        {
            CA.push("Loaded");
        }

        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");
        const Style = Object.assign({}, style);

        if (src)
        {
            Style.backgroundImage = `url(${src})`;
        }

        if (autoAdjust)
        {
            Style.paddingTop = height / width * 100 + "%"
        }
        
        return (
            <div className={CS} style={Style} />
        );
    }
}

LoadImage.propTypes =
{
    autoAdjust: PropTypes.bool,
    className: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    src: PropTypes.string,
    src2x: PropTypes.string,
    style: PropTypes.object
};

LoadImage.defaultProps =
{
    autoAdjust: false,
    className: "",
    onLoad: () => {},
    onError: () => {},
    src: "",
    src2x: "",
    style: {}
};

export default LoadImage;