
import React from "react";
import "./core.scss";

import Calculator from "Class/Calculator";
import Globals from "Class/Globals";
import ReactGA from "react-ga";
import {NiceNumber} from "Functions";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {default as JsCookie} from "js-cookie";

import Cookies from "Components/View/Cookies"
import Landing from "Components/View/Landing";
import Result from "Components/View/Result";
import Terms from "Components/View/Terms";

class Core extends React.Component
{
    constructor(props)
    {
        super(props);

        this.AddedTracking = false;
        this.CookieKey = "_minpensionar_cookies";
        this.CookiePreferenceKey = "cookiePreferences";
        this.DoRedirect = "/";
        //this.GA = "G-80MMBHFSY9";
        this.GA = "";
        this.Touch = "ontouchstart" in window;
        this.Tracking = !!this.GA;

        if (this.GA)
        {
            ReactGA.initialize(this.GA);
            ReactGA.pageview("/");
        }

        this.state =
        {
            ageIn: -1,
            ageOut: -1,
            avatar: false,
            fullTime: 1,
            hobby: -1,
            monthly: 0,
            salary: -1,
            servicePension: 1,
            step: 0
        };
    }

    componentDidMount()
    {
        this.AddTracking();
    }

    AddTracking = (confirmed) =>
    {
        const {statistics} = Globals.StorageJson(this.CookiePreferenceKey, {});
        const Allowed = statistics === undefined ? true : statistics;
        const Confirmed = confirmed || JsCookie.get(this.CookieKey) === "1";

        if (!Confirmed || !Allowed || this.AddedTracking)
        {
            return;
        }

        const Script1 = document.createElement("script");
        const Script2 = document.createElement("script");

        Script1.type = Script2.type = "text/javascript";
        Script1.text = `
            var _paq = window._paq || [];
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
                var u="https://piwik.minpension.se/";
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', '3']);
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
            })();
        `;
        Script2.text = `
            var _mtm = _mtm || [];
            _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.type='text/javascript'; g.async=true; g.defer=true; g.src='https://piwik.minpension.se/js/container_7vMjrjyi.js'; s.parentNode.insertBefore(g,s);
        `;

        document.body.appendChild(Script1);
        document.body.appendChild(Script2);
        this.AddedTracking = true;
    }

    Calculate = (interactionParam = "") =>
    {
        const {ageIn, ageOut, fullTime, hobby, salary, servicePension} = this.state;
        const Avatar = Calculator.Avatar(ageIn, ageOut, salary, hobby, servicePension, fullTime, interactionParam);
        const Payout = Calculator.Calculate(ageIn, ageOut, salary, servicePension, fullTime);

        this.setState({
            avatar: Avatar,
            monthlyPayout: Payout
        });
    }

    FormatSalary = (salary) =>
    {
        return NiceNumber(salary) + (salary === 100000 ? " kr+" : " kr");
    }

    FormatYear = (year) =>
    {
        return year + " år";
    }

    OnBack = () =>
    {
        this.DoRedirect = "/";
        this.setState({step: 0});
    }

    OnClick = () =>
    {
        const {avatar} = this.state;
        const {slug} = avatar || {};

        if (!slug)
        {
            return;
        }

        if (this.Tracking)
        {
            ReactGA.pageview("/" + slug);
        }
        
        this.DoRedirect = "/" + slug;
        this.setState({step: 1});
    }

    OnUpdate = (e, value, key) =>
    {
        const State = {};
        State[key] = value;
        this.setState(State);
        setTimeout(() => this.Calculate(key));
    }

    RouteDefault = () =>
    {
        const {
            ageIn,
            ageOut,
            avatar,
            fullTime,
            hobby,
            monthlyPayout,
            salary,
            servicePension,
            step
        } = this.state;

        let DoRedirect = "";

        if ( this.DoRedirect )
        {
            DoRedirect = <Redirect to={this.DoRedirect} />;
            this.DoRedirect = "";
        }

        return (
            <div className="Page">
                {DoRedirect}
                {step === 0 ? <Landing
                    ageIn={ageIn}
                    ageOut={ageOut}
                    formatSalary={this.FormatSalary}
                    formatYear={this.FormatYear}
                    fullTime={fullTime}
                    hobbies={Calculator.Hobbies}
                    hobby={hobby}
                    onClick={this.OnClick}
                    onUpdate={this.OnUpdate}
                    salary={salary}
                    servicePension={servicePension}
                /> : <Result
                    ageIn={ageIn}
                    ageOut={ageOut}
                    avatar={avatar}
                    formatSalary={this.FormatSalary}
                    formatYear={this.FormatYear}
                    fullTime={fullTime}
                    onBack={this.OnBack}
                    onUpdate={this.OnUpdate}
                    lastParam={this.LastParam}
                    monthlyPayout={monthlyPayout}
                    salary={salary}
                    servicePension={servicePension}
                />}
                <Cookies onConfirm={() => this.AddTracking(true)} />
            </div>
        );
    }

    render()
    {
        const CA = ["Core"];

        if (this.Touch)
        {
            CA.push("Touch");
        }
        else
        {
            CA.push("NoTouch");
        }

        const CS = CA.join(" ");

        return (
            <Router>
                <div className={CS}>
                    <Switch>
                        <Route path="/cookies" component={Terms}/>
                        <Route render={this.RouteDefault}/>
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default Core;