
/*!
 *  Feather icon.
 *
 *  @prop string className - Append a class name.
 *  @prop string feather - Feather icon name.
 *  @prop integer size - Icon size;
 *  @prop object style - Icon style attribute.
 *  @prop string title - The element title tag.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./icon.scss";

import * as FeatherIcon from "react-feather";

class Icon extends React.Component
{
    render()
    {
        const {className, feather, size, style, title} = this.props;
        const CA = ["Icon"];

        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");
        const Style = style || {};
        let Feather = feather;

        if (typeof FeatherIcon[Feather] === "undefined")
        {
            Feather = "Heart";
        }

        const IconComponent = FeatherIcon[Feather];
        const IconElement = React.createElement(IconComponent,
        {
            display: "block",
            width: "100%",
            height: "100%",
            strokeWidth: 1
        });

        if (Style.width === undefined)
        {
            Style.width = size;
        }

        if (Style.height === undefined)
        {
            Style.height = size;
        }

        return (
            <div
                className={ CS }
                style={ Style }
                title={ title }
            >
                {IconElement}
            </div>
        );
    }
}

Icon.propTypes =
{
    className: PropTypes.string,
    feather: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    title: PropTypes.string
};

Icon.defaultProps =
{
    className: "",
    feather: "Heart",
    size: "2em",
    style: false,
    title: ""
};

export default Icon;