
import React from "react";
import "./counter.scss";

import {NiceNumber} from "Functions";
import gsap, {CSSPlugin, Bounce, Power2, TweenLite} from "gsap";

gsap.registerPlugin(CSSPlugin);

class Counter extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Digits = [];
        this.Transitions = [];
        this.Value = 0;
    }

    componentDidUpdate()
    {
        this.SetNumber(this.props.value);
    }

    /**
     * Output a counter digit.
     * 
     * @param integer index - Digit index.
     *  
     * @return JSX - The digit.
     */

    Digit = (index) =>
    {
        const Numbers = [];
        const Step = 36;

        for (let i = 0; i < 10; i++)
        {
            let Rotation = i * Step;

            Numbers.push(
                <div
                    className="CounterDigitNumber"
                    style={{transform: `rotateX(${Rotation}deg)`}}
                    key={index + i}
                ><span>{i}</span></div>
            );
        }

        return (
            <div
                className="CounterDigit"
                key={index}
                ref={digit => this.Digits[index] = digit}
            >{Numbers}</div>
        );
    }

    SetNumber = (number, transition = 1) =>
    {
        const Number = parseInt(number, 10);

        if (Number === this.Value || isNaN(Number))
        {
            return;
        }

        this.Stop();
        this.Value = Number;

        const Digits = String(Number).split("");
        const NumDigits = Digits.length;
        let Sum = 0;
        let Duration, Ease;

        switch (transition)
        {
            case 2:
                Duration = 1;
                Ease = Bounce.easeOut;
                break;

            default:
                Duration = 1;
                Ease = Power2.easeOut
        }
        
        for (let i = 0; i < NumDigits; i++)
        {
            const Value = parseInt(Digits[i], 10);
            const Digit = this.Digits[NumDigits - i - 1];
            const Rotation = Sum * 360 + Value * 36;

            this.Transitions[i] = TweenLite.to(Digit, Duration, {
                ease: Ease,
                rotationX: Rotation * -1
            });

            Sum = Sum * 10 + Value;
        }
    }

    Stop = () =>
    {
        this.Transitions.forEach(transition => transition.kill())
        this.Transitions = [];
    }

    render()
    {
        const {className, suffix, value} = this.props;
        const CA = ["Counter"];

        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");
        const Digits = [];
        const Number = parseInt(value, 10);
        let Title = "";

        if (!isNaN(Number))
        {
            Title = NiceNumber(Number) + suffix;
            const NumDigits = String(Number).length;

            for (let i = 0; i < NumDigits; i++)
            {
                Digits.push(this.Digit(i));
            }
        }

        return (
            <div className={CS} title={Title}>
                <div className="CounterWrapper">
                    <div className="CounterSuffix">{suffix}</div>
                    <span className="CounterDigits">{Digits}</span>
                </div>
            </div>
        );
    }
}

Counter.defaultProps =
{
    className: "",
    suffix: "",
    value: 0
};

export default Counter;