
import React from "react";
import "./result.scss";

import Calculator from "Class/Calculator";
import Avatar from "Components/Layout/Avatar";
import Counter from "Components/Layout/Counter";
import Help from "Components/UI/Help";
import Link from "Components/UI/Link";
import MinPension from "Components/UI/MinPension";
import RangeSlider from "Components/UI/Field/RangeSlider";
import Share from "Components/UI/Share";

class Result extends React.Component
{
    constructor(props)
    {
        super(props);

        this.TextImages = true;

        this.state =
        {
            text: ""
        }
    }

    componentDidMount()
    {
        const {avatar} = this.props;
        const Text = avatar ? avatar.text || "" : "";

        this.setState({
            text: Text
        });
    }

    OnUpdate = (e, value, key) =>
    {
        const {onUpdate} = this.props;
        const {text} = this.state;

        if (text)
        {
            this.setState({text: ""});
        }

        onUpdate(e, value, key);
    }

    SvgBack = () =>
    {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="50" viewBox="0 0 25 50">
                <path className="ResultIconPath" d="M25 1L1 25L25 49"/>
            </svg>
        );
    }

    render()
    {
        const {
            ageIn,
            ageOut,
            avatar,
            onBack,
            formatSalary,
            formatYear,
            fullTime,
            monthlyPayout,
            salary,
            servicePension
        } = this.props;

        const {text} = this.state;
        const {AgesIn, AgesOut, Salaries} = Calculator;
        const {
            bubblePosition,
            hobby,
            key: avatarSrc,
            name,
            slug,
            tips: avatarTips,
            tipsImages: avatarTipsImages
        } = avatar || {};

        const PermaLink = window.location.href;
        const Message = `Jag blev ${name} som lägger pensionspengarna på ${hobby}. Ta testet du med och se hur dina val idag påverkar ditt liv i framtiden.`;
        const Title = "Min Pensionär";
        const Text = !this.TextImages ? text || (avatarTips ? avatarTips[0] : "") : "";
        const TextImage = this.TextImages ? (text ? `${slug}-00`: (avatarTipsImages ? avatarTipsImages[0] : "")) : "";
        const TextImageSrc = TextImage ? `/text/${TextImage}.png` : "";

        return (
            <div className="Result Purple">
                <div className="ResultContentWrapper">
                    <div className="ResultContent Page">
                        <div className="Content">
                            <div className="ResultLeft">
                                <Avatar
                                    bubblePosition={bubblePosition}
                                    name={name}
                                    src={avatarSrc}
                                    text={Text}
                                    textImage={TextImageSrc}
                                />
                            </div>
                            <div className="ResultRight">
                                <div className="ResultCounter">
                                    <div className="ResultCounterContent">
                                        <Counter
                                            suffix={(
                                                <div className="ResultSuffix">
                                                    kr*
                                                </div>
                                            )}
                                            value={monthlyPayout}
                                        />
                                        <div className="ResultCounterText">
                                            får du per månad <b>före skatt.</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="ResultRangeSliders">
                                    <div className="ResultRangeSlidersText">
                                        Ta reda på hur du får råd med mer i framtiden genom att dra i reglagen nedan!
                                    </div>
                                    <RangeSlider
                                        format={formatYear}
                                        id="ageIn"
                                        onChange={this.OnUpdate}
                                        max={AgesIn[2]}
                                        min={AgesIn[1]}
                                        sliderLabel="Börjar jobba"
                                        step={AgesIn[0]}
                                        value={ageIn}
                                    >
                                        <Help className="ResultHelp">
                                            Den ålder då du börjar jobba på allvar, inte bara sommarjobb och extrajobb.
                                        </Help>
                                    </RangeSlider>
                                    <RangeSlider
                                        format={formatYear}
                                        id="ageOut"
                                        onChange={this.OnUpdate}
                                        max={AgesOut[2]}
                                        min={AgesOut[1]}
                                        sliderLabel="Pensionsålder"
                                        step={AgesOut[0]}
                                        value={ageOut}
                                    >
                                        <Help className="ResultHelp">
                                            Den ålder du skulle vilja sluta arbeta helt och gå i pension.
                                        </Help>
                                    </RangeSlider>
                                    <RangeSlider
                                        format={formatSalary}
                                        id="salary"
                                        onChange={this.OnUpdate}
                                        max={Salaries[2]}
                                        min={Salaries[1]}
                                        sliderLabel="Förväntad månadslön"
                                        step={Salaries[0]}
                                        value={salary}
                                    >
                                        <Help className="ResultHelp">
                                            Osäker på vad du kan förvänta dig i lön? <Link href="https://www.scb.se/hitta-statistik/sverige-i-siffror/lonesok/Search/?lon=sociolog">Kolla här</Link>
                                        </Help>
                                    </RangeSlider>
                                    <RangeSlider
                                        id="fullTime"
                                        onChange={this.OnUpdate}
                                        sliderLabel="Anställningstyp"
                                        value={fullTime}
                                        values={["Deltid", "Heltid"]}
                                    />
                                    <RangeSlider
                                        id="servicePension"
                                        onChange={this.OnUpdate}
                                        sliderLabel="Tjänstepension"
                                        value={servicePension}
                                        values={["Nej", "Ja"]}
                                    >
                                        <Help className="ResultHelp">
                                            De flesta arbetsgivare betalar dig extra pension, men det är inte självklart. Om du har eget företag har du ingen tjänstepension. 
                                        </Help>
                                    </RangeSlider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ResultNotice">
                        *så här har vi <Help label="räknat">
                            Den pension du ser bygger på Pensionsmyndighetens typfallsmodell. Den visar pensionen i dagens penningvärde så att du kan jämföra med vad mat och hyra kostar nu. I våra beräkningar har vi räknat med födelseår 1997 och tjänstepensionen AKAP-KR. 
                        </Help>
                    </div>
                </div>
                <div className="ResultBottom">
                    <div className="ResultCtaWrapper">
                        <div className="ResultCtaContainer PageTight">
                            <div className="ResultCtaContent">
                                <p>Vill du veta mer? Logga in på minPension och se dina egna&nbsp;siffror.</p>
                                
                                <MinPension />
                            </div>
                            <div className="ResultCtaContent">
                                <Share
                                    link={PermaLink}
                                    message={Message}
                                    title={Title}
                                />
                            </div>
                        </div>
                        <div className="ResultBottomLeft">
                            <Link
                                className="ResultBack"
                                onClick={onBack}
                            >
                                <div className="ResultIcon">{this.SvgBack()}</div>
                                Tillbaka
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Result.defaultProps =
{
    ageIn: 0,
    ageOut: 0,
    avatar: false,
    formatSalary: () => {},
    formatYear: () => {},
    fullTime: 1,
    onBack: () => {},
    onUpdate: () => {},
    monthlyPayout: 0,
    salary: 0,
    servicePension: 0
}

export default Result;