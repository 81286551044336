
import React from "react";
import "./selectfield.scss";

class SelectField extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Container = false;
        this.Input = false;
        this.Touch = "ontouchstart" in window;

        this.state =
        {
            expand: false,
            height: 0,
            value: -1,
            width: 0
        };
    }

    componentDidMount()
    {
        this.Adjust();

        window.addEventListener("resize", this.Adjust);
    }

    componentDidUpdate()
    {
        const {value} = this.props;
        const {value: currentValue} = this.state;

        if (value !== currentValue)
        {
            this.setState({value});
        }
    }

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.Adjust);
    }

    Adjust = () =>
    {
        if (!this.Container)
        {
            return;
        }

        this.setState({
            height: this.Container.offsetHeight,
            width: this.Container.offsetWidth
        });
    }

    Collapse = () =>
    {
        this.setState({
            expand: false
        });
    }

    OnChange = (e) =>
    {
        const {id, onChange} = this.props;
        let Value = this.Input.value;

        if (typeof Value === "string" && Value.match(/^\d*$/))
        {
            Value = parseInt(Value, 10);
        }

        this.setState({
            value: Value
        });

        onChange(e, Value, id);
    }

    OnClick = (e) =>
    {
        const {disabled} = this.props;
        const {expand} = this.state; 

        if (disabled)
        {
            return;
        }

        this.setState({
            expand: !expand
        });
    }

    OnClickOption = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();

        const {id, onChange, options} = this.props;
        const Value = parseInt(e.currentTarget.id, 10);

        if (options[Value] === undefined)
        {
            return;
        }

        this.setState({
            expand: false
        })

        onChange(null, Value, id);
    }

    OnKey = (e) =>
    {
        const {disabled, options} = this.props;
        const {expand, value} = this.state;
        const Value = String(value);
        const Keys = Object.keys(options);
        let Index = Keys.indexOf(Value);

        if (disabled)
        {
            return;
        }

        if (Index < 0)        
        {
            Index = 0;
        }

        switch (e.which)
        {
            case 32: // Space
                this.setState({expand: !expand});
                break;
            case 37: // Left
            case 38: // Up
                this.SetIndex(Index - 1);
                break;
            case 39: // Right
            case 40: // Down
                this.SetIndex(Index + 1);
                break;
            default:
                return;
        }

        e.stopPropagation();
        e.preventDefault();
    }

    SetIndex = (index) =>
    {
        const {id, onChange, options} = this.props;
        const Keys = Object.keys(options);

        if (Keys[index] === undefined)
        {
            return;
        }

        const Value = parseInt(Keys[index], 10);

        this.setState({
            value: Value
        });

        onChange(null, Value, id);
    }

    SvgArrow = () =>
    {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="25" viewBox="0 25 50 25">
                <path className="SelectFieldIconPath" d="M1 25L25 49L49 25"/>
            </svg>
        );
    }

    render()
    {
        const {
            children,
            className,
            disabled,
            label,
            options,
            placeholder
        } = this.props;
        const {expand, height, value, width} = this.state;
        const CA = ["SelectField"];

        if (disabled)
        {
            CA.push("Disabled");
        }

        if (expand)
        {
            CA.push("Expand");
        }

        if (className)
        {
            CA.push(className);
        }

        const Options = [];
        const Input = [];
        let Menu;

        if (this.Touch)
        {
            if (placeholder)
            {
                Options.push(<option key={-1} value={-1}>{placeholder}</option>);
            }

            for (let key in options)
            {
                Options.push(<option key={key} value={key}>{options[key]}</option>);
            }

            Input.push(
                <select
                    className="Input"
                    key="select"
                    onChange={this.OnChange}
                    ref={input => this.Input = input}
                    value={value}
                >
                    {Options}
                </select>
            );
        }
        else
        {
            const NoSelect = options[value] === undefined;
            const Selected = NoSelect ? placeholder : options[value];

            Input.push(
                <div
                    className="Input"
                    key="custom"
                    onClick={this.OnClick}
                    onKeyDown={this.OnKey}
                    tabIndex="0"
                >
                    <div className="InputValue">{Selected}</div>
                </div>
            );

            if (expand)
            {
                let ClassName;

                if (placeholder)
                {
                    if (NoSelect)
                    {
                        ClassName = "SelectFieldOption Selected";
                    }
                    else
                    {
                        ClassName = "SelectFieldOption";
                    }

                    Options.push(
                        <div
                            className={ClassName}
                            id={-1}
                            key={-1}
                            onClick={this.OnClickOption}
                        >{placeholder}</div>
                    );
                }

                for (let key in options)
                {
                    let Key = parseInt(key, 10);

                    if (Key === value)
                    {
                        ClassName = "SelectFieldOption Selected";
                    }
                    else
                    {
                        ClassName = "SelectFieldOption";
                    }

                    Options.push(
                        <div
                            className={ClassName}
                            id={key}
                            key={key}
                            onClick={this.OnClickOption}
                        >{options[key]}</div>
                    );
                }

                Menu = (
                    <div
                        className="SelectFieldOptionsWrapper"
                        key="menu"
                        style={{top: height}}
                    >
                        <div
                            className="SelectFieldBackdrop"
                            onClick={this.Collapse}
                        />
                        <div
                            className="SelectFieldOptions"
                            style={{width}}
                        >
                            <div className="SelectFieldOptionsContainer">
                                <div className="SelectFieldOptionsScroll">{Options}</div>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        const CS = CA.join(" ");

        return (
            <div className={CS} ref={container => this.Container = container}>
                {label ? (
                    <div className="SelectFieldLabel">
                        <span>{label}</span>
                        <div className="SelectFieldAppend">{children}</div>
                    </div>
                ) : ""}
                <div className="SelectFieldInputContainer">
                    {Input}
                    <div className="SelectFieldIcon">
                        {this.SvgArrow()}
                    </div>
                </div>
                {Menu}
            </div>
        );
    }
}

SelectField.defaultProps =
{
    className: "",
    id: "",
    label: "",
    onChange: () => {},
    options: [],
    placeholder: "",
    value: -1
};

export default SelectField;