
import React from "react";
import "./share.scss";

import Link from "Components/UI/Link";

class Share extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.Button = false;
        this.Menu = false;
        this.Mounted = false;

        this.state =
        {
            expand: false
        }
    }

    componentDidMount()
    {
        this.Mounted = true;
    }

    componentWillUnmount()
    {
        this.Mounted = false;
        document.removeEventListener("focusin", this.OnOtherFocus);
    }

    Block = (e) =>
    {
        const {blockDefault} = this.props;

        if (!blockDefault)
        {
            return;
        }

        e.stopPropagation();
        e.preventDefault();
    }

    Collapse = (e) =>
    {
        this.Block(e);

        document.removeEventListener("focusin", this.OnOtherFocus);
        this.setState({expand: false});
    }

    Expand = (e) =>
    {
        this.Block(e);
        this.BlockClick = true;

        document.addEventListener("focusin", this.OnOtherFocus);
        this.setState({expand: true});

        setTimeout(() => this.BlockClick = false, 500);
    }

    OnClick = (e) =>
    {
        this.Block(e);
        const {expand} = this.state;

        if (this.BlockClick)
        {
            return;
        }
        if (expand)
        {
            this.Collapse(e);
        }
        else
        {
            this.Expand(e);
        }
    }

    OnKey = (e) =>
    {
        switch (e.which)
        {
            case 13:
            case 32:
                this.Toggle(e);
                break;
            default:
        }
    }

    OnOtherFocus = (e) =>
    {
        if (e.target === this.Button || (this.Menu && this.Menu.contains(e.target)))
        {
            return;
        }

        this.Collapse(e);
    }

    Toggle = (e) =>
    {
        this.Block(e);

        const {expand} = this.state;
        this.setState({expand: !expand});
    }

    render()
    {
        const {className, label, link, message, title} = this.props;
        const {expand} = this.state;
        const CA = ["Share"];

        if (expand)
        {
            CA.push("Expand");
        }

        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");
        const EncodedLink = encodeURI(link);
        const EncodedMessage = encodeURI(message);
        const EncodedMessageAndLink = encodeURI(`${message} ${link}`);
        const EncodedTitle = encodeURI(title);
        const Facebook = `https://www.facebook.com/sharer/sharer.php?u=${EncodedLink}`;
        const LinkedIn = `https://www.linkedin.com/shareArticle?mini=true&url=${EncodedLink}&title=${EncodedTitle}&summary=${EncodedMessage}`;
        const Twitter = `https://twitter.com/intent/tweet?text=${EncodedMessageAndLink}`;

        return (
            <div className={CS}>
                <div
                    className="ShareButton"
                    onClick={this.OnClick}
                    onFocus={this.Expand}
                    onKeyDown={this.OnKey}
                    ref={button => this.Button = button}
                    tabIndex="0"
                ><span>{label}</span></div>
                {expand ? (
                    <span>
                        <div
                            className="ShareBackdrop"
                            onClick={this.Collapse}
                        />
                        <div
                            className="ShareMenu"
                            ref={menu => this.Menu = menu}
                        >
                            <Link href={Facebook}>Facebook</Link>
                            <Link href={Twitter}>Twitter</Link>
                            <Link href={LinkedIn}>LinkedIn</Link>
                        </div>
                    </span>
                ) : ""}
            </div>
        );
    }
}

Share.defaultProps =
{
    blockDefault: false,
    className: "",
    label: "Dela",
    link: "",
    message: "",
    title: ""
};

export default Share;