
/*!
 *  Icon Button.
 *
 *  @prop boolean active - Whether the button should be rendered as active.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the button should be disabled.
 *  @prop string feather - Icon src.
 *  @prop string href - Optional link href.
 *  @prop string id - Button ID.
 *  @prop boolean loading - Whether to display a spinner instead of the icon.
 *  @prop function onClick - Callback for when the button is clicked.
 *  @prop integer size - Icon size;
 *  @prop string target - Optional. Link target window when a href has been specified.
 *  @prop string title - The button title tag.
 *  @prop string to - URI path when used for internal navigation.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./iconbutton.scss";

import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";

class IconButton extends React.Component
{
    render()
    {
        const {active, className, disabled, feather, size, title} = this.props;
        const CA = ["IconButton"];

        if (active)
        {
            CA.push("Active");
        }

        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");

        return (

            <Link
                {...this.props}

                className={CS}
                disabled={disabled}
            >
                <Icon
                    feather={feather}
                    size={size}
                    title={title}
                />
            </Link>
        );
    }
}

IconButton.propTypes =
{
    className: PropTypes.string,
    title: PropTypes.string
};

IconButton.defaultProps =
{
    className: "",
    title: ""
};

export default IconButton;