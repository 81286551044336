
import React from "react";
import PropTypes from "prop-types";
import "./button.scss";

import Link from "Components/UI/Link";

class Button extends React.Component
{
    OnClick = (e) =>
    {
        const {disabled, onClick} = this.props;

        if (disabled)
        {
            return;
        }

        onClick(e);
    }

    OnKey = (e) =>
    {
        switch (e.which)
        {
            case 13:
                this.OnClick(e);
                break;

            default:
                return
        }

        e.stopPropagation();
        e.preventDefault();
    }

    render() {

        const
        {
            children,
            className,
            disabled,
            href,
            label,
            target,
            title,
            to 
        } = this.props;

        const CA = ["Button"];

        if (disabled)
        {
            CA.push("Disabled");
        }

        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");

        if (href || to)
        {
            return (
                <Link
                    className={CS}
                    href={href}
                    onClick={this.OnClick}
                    target={target}
                    title={title}
                    to={to}
                >
                    <span>{label || children}</span>
                </Link>
            );
        }
        else
        {
            return (
                <div
                    className={CS}
                    onClick={this.OnClick}
                    onKeyDown={this.OnKey}
                    tabIndex="0"
                    title={title}
                >
                    <span>{label || children}</span>
                </div>
            );
        }
    }
}

Button.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string

};

Button.defaultProps =
{
    className: "",
    disabled: false,
    href: "",
    label: "",
    onClick: () => {},
    target: "_blank",
    title: "",
    to: ""
};

export default Button;