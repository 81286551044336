import React from "react";
import "./cookies.scss";
import {default as JsCookie} from "js-cookie";

class Cookies extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Key = "_minpensionar_cookies";
        this.Url = "/cookies";
        this.state =
        {
            confirmed: true,
            denied: false
        };
    }

    componentDidMount()
    {
        const Value = JsCookie.get(this.Key);
        this.setState({
            confirmed: Value === "1",
            denied: Value === "2"
        });
    }

    Confirm = () =>
    {
        JsCookie.set(this.Key, "1", {expires: 14});
        this.setState({confirmed: true, denied: false});
        this.props.onConfirm();
    }

    Deny = () =>
    {
        JsCookie.set(this.Key, "2", {expires: 14});
        this.setState({confirmed: false, denied: true});
    }

    OnKey = (e) =>
    {
        switch (e.which)
        {
            case 13:
                this.Confirm();
                break;
            default:
                return;
        }
        e.preventDefault();
        e.stopPropagation();
    }

    OnKeyDeny = (e) =>
    {
        switch (e.which)
        {
            case 13:
                this.Denied();
                break;
            default:
                return;
        }
        e.preventDefault();
        e.stopPropagation();
    }

    render()
    {
        const {confirmed, denied} = this.state;
        if (confirmed || denied)
        {
            return "";
        }
        return (
            <div className="Cookies">
                <div className="CookiesContent">
                    <h2>Min Pensionär använder cookies</h2>
                    <div className="CookiesText">Min Pensionär använder alltid cookies (kakor) för att säkerställa webbplatsens funktioner och säkerhet. Du kan också acceptera att vi använder cookies för att undersöka hur Min Pensionär används.</div>
                    <div className="CookiesText">
                        <a className="CookiesLink" href={this.Url} target="_blank" rel="noopener noreferrer" >Läs mer om hur vi använder cookies och om hur du kan anpassa dina inställningar</a>
                    </div>
                    <div
                        className="CookiesConfirm"
                        onClick={this.Confirm}
                        onKeyDown={this.OnKey}
                        tabIndex="0"
                    >Jag accepterar</div>
                    <div
                        className="CookiesDeny"
                        onClick={this.Deny}
                        onKeyDown={this.OnKeyDeny}
                        tabIndex="0"
                    >Nej tack</div>
                </div>
            </div>
        );
    }
}

Cookies.defaultProps =
{
    onConfirm: () => {}
};

export default Cookies;