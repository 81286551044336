
import React from "react";
import "./rangeslider.scss";

import {CapFloat} from "Functions";
import gsap, {Bounce, CSSPlugin, Power0, TweenLite} from "gsap";

gsap.registerPlugin(CSSPlugin);

class RangeSlider extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Animation = false;
        this.Clicked = false;
        this.Direction = false;
        this.Fill = false;
        this.Handle = false;
        this.Moved = false;
        this.Origin = [0, 0];
        this.Progress = 0;
        this.Range = [0, 0];
        this.Slider = false;
        this.Transition1 = false;
        this.Transition2 = false;
        this.ValueWidth = 0;
        this.Wrapper = false;
        this.Value = 0;

        this.state =
        {
            dragging: false,
            value: 0
        };
    }

    /*
     * Set handle position and add listeners on mount.
     * 
     * @return void
     */

    componentDidMount()
    {
        this.SetHandle();

        window.addEventListener("resize", this.OnResize);
    }

    /*
     * Update field when a new value is received.
     * 
     * @return void
     */

    componentDidUpdate()
    {
        const {value} = this.props;
        const {dragging} = this.state;

        if (!dragging && value !== this.Value)
        {
            this.SetHandle(value, 1);
        }
    }

    /*
     * Remove listeners on unmount.
     * 
     * @return void
     */

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.OnResize);
    }

    /*
     * Update the value when the slider is clicked.
     *
     * @param object e - The click event.
     * 
     * @return void
     */

    OnClick = (e) =>
    {
        // Block click event after drag interaction.
        if (this.Moved)
        {
            return;
        }

        this.Clicked = true;

        const {disabled, values} = this.props;
        const {pageX} = e;
        const NumValues = Object.keys(values).length;

        if (e.button !== 0 || !this.Handle || !this.Slider || disabled)
        {
            return;
        }

        const Rect = this.Slider.getBoundingClientRect();
        const Offset = Rect.left;
        const Width = this.Slider.offsetWidth;
        let Progress = CapFloat((pageX - Offset) / Width);
        let SetValue;

        if (NumValues)
        {
            SetValue = Math.round(Progress * (NumValues - 1));
            Progress = SetValue / (NumValues - 1);
        }

        this.SetHandlePosition(Progress, SetValue, 1);
    }

    /*
     * Callback when drag stops.
     *
     * @param object e - The mouse event.
     * 
     * @return void
     */

    OnDragEnd = (e) =>
    {
        const {max, min, values} = this.props;
        const {value} = this.state;
        const NumValues = Object.keys(values).length;

        window.removeEventListener("mousemove", this.OnDragMove);
        window.removeEventListener("mouseup", this.OnDragEnd);

        if (!this.Clicked && this.Moved)
        {
            const Progress = NumValues ? value / (NumValues - 1) : (value - min) / (max - min);

            this.SetHandlePosition(Progress, value, 2);
        }

        this.setState({
            dragging: false
        });

        setTimeout(() => this.Moved = false);
    }

    /*
     * Callback when dragged.
     *
     * @param object e - The mouse event.
     * @param boolean checkDirection - Whether to block interaction when moving mostly in the Y-direction.
     * 
     * @return void
     */

    OnDragMove = (e, checkDirection) =>
    {
        if (checkDirection && this.Direction === 1)
        {
            return;
        }

        const {values} = this.props;
        const {pageX, pageY} = e;
        const NumValues = Object.keys(values).length;

        if (checkDirection && this.Direction === false)
        {
            const [OX, OY] = this.Origin;
            const SX = Math.abs(pageX - OX);
            const SY = Math.abs(pageY - OY);

            if (SY > SX)
            {
                this.Direction = 1;
                return;
            }

            if (SX > SY)
            {
                this.Direction = 0;
            }
        }

        const [Offset, Width, Handle, Adjust] = this.Range;
        const Progress = CapFloat((pageX - Offset - Adjust) / (Width - Handle));
        let SetValue;

        if (NumValues)
        {
            SetValue = Math.round(Progress * (NumValues - 1));
        }

        this.Moved = true;
        this.SetHandlePosition(Progress, SetValue);
    }

    /*
     * Callback when a drag is initiated.
     *
     * @param object e - The mouse event.
     * 
     * @return void
     */

    OnDragStart = (e) =>
    {
        const {disabled} = this.props;
        const {button, pageX, pageY} = e;

        if (button !== 0 || !this.Handle || !this.Slider || disabled)
        {
            return;
        }

        this.Stop();
        e.preventDefault();
        e.stopPropagation();

        const HandleRect = this.Handle.getBoundingClientRect();
        const HandleOffset = HandleRect.left;
        const HandleWidth = this.Handle.offsetWidth;
        const SliderRect = this.Slider.getBoundingClientRect();
        const SliderOffset = SliderRect.left;
        const SliderWidth = this.Slider.offsetWidth;
        const AdjustOffset = pageX - HandleOffset;
        const Adjust = (AdjustOffset > 0 && AdjustOffset <= HandleWidth) ? AdjustOffset : 0;

        this.Clicked = false;
        this.Direction = false;
        this.Origin = [pageX, pageY];
        this.Range = [SliderOffset, SliderWidth, HandleWidth, Adjust];

        window.addEventListener("mousemove", this.OnDragMove);
        window.addEventListener("mouseup", this.OnDragEnd);

        this.setState({
            dragging: true
        });

        this.Slider.focus();
    }

    /*
     * Callback when a key is pressed.
     *
     * @param object e - The key event.
     * 
     * @return void
     */

    OnKey = (e) =>
    {
        const {disabled, max, min, step, values} = this.props;
        const {value} = this.state;
        const Keys = Object.keys(values);
        const NumValues = Keys.length;
        let Direction;

        if (disabled)
        {
            return;
        }

        switch (e.which)
        {
            case 32: // Space
                // Wrap around when max/last is reached.
                if ((NumValues && value >= NumValues -1) || (!NumValues && value >= max))
                {
                    Direction = NumValues ? (NumValues - 1) * -1 : (max - min) * -1;
                }
                else
                {
                    Direction = 1;
                }
                break;
            case 37: // Left
                Direction = -1;
                break;
            case 39: // Right
                Direction = 1;
                break;
            default:
                return;
        }

        e.stopPropagation();
        e.preventDefault();

        if (NumValues)
        {
            const NewIndex = parseInt(value, 10) + Direction;
            const Progress = NewIndex / (NumValues - 1);

            if (values[NewIndex] === undefined)
            {
                return;
            }

            this.Stop();
            this.SetHandlePosition(Progress, NewIndex, 1);
        }
        else
        {
            const NewValue = CapFloat(value + step * Direction, min, max);
            const Progress = (NewValue - min) / (max - min);

            this.Stop();
            this.SetHandlePosition(Progress, NewValue, 1);
        }
    }

    /*
     * Callback when the client window is resized.
     * 
     * @return void
     */

    OnResize = () =>
    {
        this.SetHandlePosition(this.Progress, this.Value, 0);
    }

    /*
     * Callback when touch drag stops.
     *
     * @param object e - The touch event.
     * 
     * @return void
     */

    OnTouchEnd = (e) =>
    {
        this.OnDragEnd();
    }

    /*
     * Callback when touch dragged.
     *
     * @param object e - The touch event.
     * 
     * @return void
     */

    OnTouchMove = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();

        this.OnDragMove(e.touches[0], true);
    }

    /*
     * Callback when a touch drag is initiated.
     *
     * @param object e - The touch event.
     * 
     * @return void
     */

    OnTouchStart = (e) =>
    {
        const {disabled} = this.props;
        const {pageX, pageY} = e.touches[0];

        if (!this.Handle || !this.Slider || disabled)
        {
            return;
        }

        this.Stop();
        e.stopPropagation();
        e.preventDefault();

        const HandleRect = this.Handle.getBoundingClientRect();
        const HandleOffset = HandleRect.left;
        const HandleWidth = this.Handle.offsetWidth;
        const SliderRect = this.Slider.getBoundingClientRect();
        const SliderOffset = SliderRect.left;
        const SliderWidth = this.Slider.offsetWidth;
        const AdjustOffset = pageX - HandleOffset;
        const Adjust = (AdjustOffset > 0 && AdjustOffset <= HandleWidth) ? AdjustOffset : 0;

        this.Clicked = false;
        this.Direction = false;
        this.Origin = [pageX, pageY];
        this.Range = [SliderOffset, SliderWidth, HandleWidth, Adjust];

        this.setState({
            dragging: true
        });

        this.Slider.focus();
    }

    /*
     * Register a value elements width when it mounts.
     *
     * @param object value - The value JSX object.
     * 
     * @return void
     */

    OnValue = (value) =>
    {
        if (!value)
        {
            return;
        }
        
        const Width = value.offsetWidth;

        if (Width <= this.ValueWidth)
        {
            return;
        }

        this.ValueWidth = Width;
        this.forceUpdate();
    }

    /*
     * Set the value and handle position.
     *
     * @param int setValue - The new value.
     * @param int transition - 0 = instant, 1 = slide, 2 = bounce.
     * 
     * @return void
     */

    SetHandle = (setValue, transition = 0) =>
    {
        const {defaultValue, max, min, value, values} = this.props;
        const Keys = Object.keys(values);

        if (Keys.length)
        {
            const Value = setValue === undefined ? (value === false ? defaultValue : value) : setValue;
            let Index = Keys.indexOf(Value);

            if (Index < 0)
            {
                Index = (Value >= 0 && Value < Keys.length) ? Value : 0;
            }

            const Progress = Index / (Keys.length - 1);

            this.SetHandlePosition(Progress < 0 ? 0 : Progress, Index, transition);
        }
        else
        {
            const Value = CapFloat(parseInt(setValue === undefined ? value : setValue, 10), min, max);
            const Progress = (Value - min) / (max - min);

            this.SetHandlePosition(Progress, Value, transition);
        }
    }

    /*
     * Set the handle position.
     *
     * @param float percent - The handle position as a percent of the field width.
     * @param int setValue - Optional. Force a field value instead of calculating it.
     * @param int transition - 0 = instant, 1 = slide, 2 = bounce.
     * 
     * @return void
     */

    SetHandlePosition = (percent = 0, setValue, transition = 0) =>
    {
        this.Progress = percent;

        const {id, onChange, max, min, step, values} = this.props;
        const {value} = this.state;
        const NumValues = Object.keys(values).length;

        if (!this.Fill || !this.Handle || !this.Slider || !this.Wrapper)
        {
            return;
        }

        const PositionHandle = (this.Slider.offsetWidth - this.Handle.offsetWidth) * percent;
        const PositionValues = (NumValues - 1) * this.ValueWidth * percent;
        let Duration, Ease, Value;

        if (NumValues)
        {
            Value = setValue || 0;
        }
        else
        {
            Value = setValue || Math.round((min + (max - min) * percent) / step) * step;
        }

        if (Value !== value)
        {
            this.Value = Value;
            this.setState({value: Value});
            onChange(null, Value, id);
        }

        switch (transition)
        {
            case 1:
                Duration = 0.2;
                Ease = Power0.easeInOut;
                break;
            case 2:
                Duration = 1;
                Ease = Bounce.easeOut;
                break;
            default:
                Duration = 0;
                Ease = null;
        }

        this.Transition1 = TweenLite.to(this.Handle, Duration, {
            x: PositionHandle,
            ease: Ease
        });
        this.Transition2 = TweenLite.to(this.Wrapper, Duration, {
            x: -PositionValues,
            ease: Ease
        });
        this.Transition3 = TweenLite.to(this.Fill, Duration, {
            width: PositionHandle,
            ease: Ease
        });
    }

    /*
     * Stop all transitions.
     * 
     * @return void
     */

    Stop = () =>
    {
        if (this.Transition1)
        {
            this.Transition1.kill();
        }

        if (this.Transition2)
        {
            this.Transition2.kill();
        }

        if (this.Transition3)
        {
            this.Transition3.kill();
        }
    }

    render()
    {
        const {
            children,
            className,
            disabled,
            format,
            max,
            min,
            sliderLabel,
            values
        } = this.props;
        const {dragging, value} = this.state;
        const CA = ["RangeSlider"];

        if (disabled)
        {
            CA.push("Disabled");
        }

        if (dragging)
        {
            CA.push("Dragging");
        }

        if (className)
        {
            CA.push(className);
        }

        const Append = children ? <div className="RangeSliderAppend">{children}</div> : "";
        const Values = [];
        const Keys = Object.keys(values);
        const NumValues = Keys.length;
        const Style = {};
        let First, Last, Value;

        if (NumValues)
        {
            CA.push("HasValues");

            if (NumValues < 3)
            {
                CA.push("HideValues");
            }

            First = values[Keys[0]];
            Last = values[Keys[NumValues - 1]];
            Value = values[Keys[value] || Keys[0]];

            for (let key in values)
            {
                Values.push(
                    <div className="RangeSliderValue" key={key} style={{width: this.ValueWidth}}>
                        <span ref={this.OnValue}>{values[key]}</span>
                    </div>
                );
            }

            Style.width = this.ValueWidth;
            Style.marginLeft = this.ValueWidth / -2;
        }
        else
        {
            CA.push("NoValues");
            First = format ? format(min) : min;
            Last = format ? format(max) : max;
            Value = format ? format(value) : value;
            Values.push(
                <div className="RangeSliderValue" key="value">
                    <span>{Value}</span>
                </div>
            );
        }

        const CS = CA.join(" ");

        return (
            <div
                className={CS}
                ref={slider => this.Slider = slider}
                tabIndex="0"
                title={`${sliderLabel}: ${Value}`}
            >
                <div className="RangeSliderFirst"><span>{First}</span></div>
                <div className="RangeSliderLast"><span>{Last}</span>{Append}</div>
                <div
                    className="RangeSliderBarContainer"
                    onClick={this.OnClick}
                    onKeyDown={this.OnKey}
                    onMouseDown={this.OnDragStart}
                    onTouchStart={this.OnTouchStart}
                    onTouchMove={this.OnTouchMove}
                    onTouchEnd={this.OnTouchEnd}
                >
                    <div className="RangeSliderBar">
                        <div className="RangeSliderBarFill" ref={fill => this.Fill = fill} />
                    </div>
                    <div className="RangeSliderHandle" ref={handle => this.Handle = handle}>
                        <span className="RangeSliderHandleLabel">{sliderLabel}</span>
                        <div className="RangeSliderValuesContainer" style={Style}>
                            <div className="RangeSliderValues">
                                <div className="RangeSliderValuesWrapper" ref={wrapper => this.Wrapper = wrapper}>
                                    {Values}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

RangeSlider.defaultProps =
{
    className: "",
    defaultValue: false,
    format: false,
    max: 100,
    min: 0,
    onChange: () => {},
    sliderLabel: "",
    step: 1,
    values: [],
    value: -1
};

export default RangeSlider;