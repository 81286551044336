
import React from "react";
import "./checkbox.scss";

import Icon from "Components/Layout/Icon";

class Checkbox extends React.Component
{
    OnClick = (e) =>
    {
        const {checked, disabled, id, onChange} = this.props;

        if (disabled)
        {
            return;
        }

        onChange(e, !checked, id);
    }

    OnKey = (e) =>
    {
        const {checked, disabled, id, onChange} = this.props;

        if (e.which !== 32 || disabled)
        {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        onChange(e, !checked, id);
    }

    render()
    {
        const {checked, children, className, disabled, text} = this.props;
        const CA = ["Checkbox"];
        
        if (checked)
        {
            CA.push("Checked");
        }

        if (disabled)
        {
            CA.push("Disabled");
        }
        
        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");

        return (
            <div
                className={CS}
                onClick={this.OnClick}
                onKeyDown={this.OnKey}
                tabIndex="0"
            >
                <div className="CheckboxInput">
                    {checked ? <Icon feather="Check" /> : ""}
                </div>
                <div className="CheckboxText">
                    {children || text}
                </div>
            </div>
        );
    }
}

Checkbox.defaultProps =
{
    checked: false,
    className: "",
    disabled: false,
    id: "",
    onChange: () => {},
    text: ""
};

export default Checkbox;