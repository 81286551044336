
import React from "react";
import "./minpension.scss";

import Link from "Components/UI/Link";

class MinPension extends React.Component
{
    Svg = () =>
    {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="796.8" height="173.4" viewBox="0 0 796.8 173.4">
                <path className="st5" d="M789.6,173.4l-779.9,0c-5.4,0-9.8-4.4-9.8-9.8L0,9.8C0,4.4,4.4,0,9.8,0l779.9,0c4,0,7.2,3.2,7.2,7.2l0,159C796.8,170.2,793.6,173.4,789.6,173.4z"/>
                <polygon className="st6" points="707.1,115.4 703.4,111.7 726.5,88.6 703.4,65.5 707.1,61.7 734,88.6"/>
                <polygon className="st2" points="103.3,123.5 91.6,123.5 91.6,123.5 79.8,123.5 79.8,135.3 91.6,135.3 91.6,135.3 103.3,135.3 115.1,135.3 115.1,123.5"/>
                <polygon className="st3" points="337,123.5 325.2,123.5 313.4,123.5 313.4,135.3 325.2,135.3 337,135.3 348.8,135.3 348.8,123.5"/>
                <polygon className="st1" points="496.4,123.5 484.6,123.5 484.6,135.3 496.4,135.3 508.2,135.3 508.2,123.5"/>
                <rect x="218.5" y="123.5" className="st0" width="11.8" height="11.8"/>
                <path className="st6" d="M161.2,68l0,43.4l-11.7,0l0-43.4L161.2,68z"/>
                <path className="st6" d="M389.6,80.8l-11,0c-0.1-3.3-1.8-5-5-5c-3.1,0-4.8,1.6-4.8,4.3c0,2.7,1.7,3.9,7.6,5
                c10.3,1.7,14.1,5.9,14.1,13.2c0,9.3-7.1,14.1-16.8,14.1c-10.5,0-16.7-5.5-17-14.1h11c0.1,3.5,2.2,5.3,6.3,5.3
                c3.4,0,5.4-1.5,5.4-4.1c0-2.8-1.7-4.3-7.9-5.4c-9.5-1.6-13.9-5.7-13.9-12.8c0-4,1.4-7.4,4.2-10c2.8-2.7,6.8-4.1,11.8-4.1C382.8,67,389.2,72.5,389.6,80.8z"/>
                <path className="st6" d="M410.9,68v43.4l-11.7,0l0-43.4L410.9,68z"/>
                <path className="st6" d="M458.3,73.4c4.4,4.2,6.5,9.6,6.5,16.3c0,6.7-2.1,12.2-6.5,16.4c-4.3,4.2-9.8,6.3-16.4,6.3c-6.6,0-12-2.1-16.3-6.3c-4.3-4.2-6.4-9.7-6.4-16.4c0-6.7,2.1-12.1,6.4-16.3c4.3-4.2,9.7-6.3,16.3-6.3C448.6,67,454.1,69.2,458.3,73.4z M433.9,80.8c-2.1,2.3-3.2,5.3-3.2,8.9c0,3.6,1,6.6,3.2,9c2.1,2.3,4.8,3.5,8.1,3.5c6.6,0,11.2-5.3,11.2-12.5c0-7.3-4.5-12.5-11.2-12.5C438.7,77.2,436.1,78.4,433.9,80.8z"/>
                <path className="st6" d="M411.8,55.5c0,3.8-3,6.6-6.8,6.6c-3.8,0-6.8-2.8-6.8-6.6c0-3.7,3-6.6,6.8-6.6C408.7,48.9,411.8,51.8,411.8,55.5z"/>
                <path className="st6" d="M304.9,89.7c0-14.8-8.1-22.6-20.1-22.6c-6.4,0-11.6,2.1-15.6,6.4c-4,4.2-6,9.7-6,16.3c0,6.8,2,12.2,5.9,16.4c4,4.1,9.3,6.2,15.7,6.2c9.6,0,16.2-4.5,19.2-12.7l-11.2,0c-1.6,2.1-4.1,3.2-7.7,3.2c-5.8,0-9.8-3.6-10.4-9l30.2,0v-0.7h0V89.7z M284.5,76.3c5.5,0,9.1,3.6,9.2,9.1l-18.9,0C275.3,80,279,76.3,284.5,76.3z"/>
                <path className="st6" d="M193.7,67c-5,0-7.4,1.8-9.8,5.4V68h-1.6l-9.5,0h-0.7l0,43.4h0.7l11,0h0l0-22.1c0-7.9,2.1-11.5,6.4-11.5c4.1,0,5.4,2.5,5.4,7.8l0,25.8l11.8,0l0-28.2C207.5,73.2,202.8,67,193.7,67z"/>
                <path className="st6" d="M161.7,55.5c0,3.8-3,6.6-6.8,6.6c-3.8,0-6.8-2.8-6.8-6.6c0-3.7,3-6.6,6.8-6.6
                C158.6,48.9,161.7,51.8,161.7,55.5z"/>
                <path className="st6" d="M334.9,67c-5,0-7.4,1.8-9.8,5.4V68h-1.6h-9.5h-0.7l0,43.4h0.7l11,0h0V87.8c0.2-6.4,2.1-9.9,6.4-9.9c4.1,0,5.4,2.5,5.4,7.8l0,25.8l11.8,0l0-28.2C348.8,73.2,344.1,67,334.9,67z"/>
                <path className="st6" d="M494.4,67c-5,0-7.4,1.8-9.8,5.4V68H483l-9.5,0h-0.7l0,43.4h0.7l11,0h0V87.8c0.2-6.4,2.1-9.9,6.4-9.9c4.1,0,5.4,2.5,5.4,7.8l0,25.8l11.8,0l0-28.2C508.2,73.2,503.5,67,494.4,67z"/>
                <path className="st6" d="M123.9,67c-5.8,0-9.1,3.2-11.3,7.6c-2.1-5-5.2-7.6-10.8-7.6c-4.8,0-8.1,1.8-10.2,5.3V68l-11.8,0l0,43.5l11.8,0v-0.1h0l0-21.4c0-8.6,1.9-12.3,6.3-12.3c4,0,5.4,2.7,5.4,7.9l0,25.8l11.8,0l0-22.4c0-7.5,1.7-11.1,6.1-11.1c4,0,5.7,2.6,5.7,7.8l0,25.8l11.8,0l0-28.2C138.7,73.2,132.9,67,123.9,67z"/>
                <path className="st6" d="M237.8,51.5c6.4,0,11.6,1.8,15.3,5.4c3.9,3.5,5.7,8.2,5.7,14c0,5.7-1.9,10.4-5.7,14
                c-3.9,3.6-8.9,5.4-15.3,5.4l-7.5,0l0,21.2l-11.7,0l0-59.9L237.8,51.5z M230.2,62.4v16.7l7.5,0c5.6,0,9.1-3.1,9.1-8.3c0-5.4-3.5-8.4-9.1-8.4L230.2,62.4z"/>
                <path className="st6" d="M520.1,112.6c-4,0-6.2-5-3.4-7.8c2.8-3,8.3-0.8,7.9,3.3C524.7,110.6,522.6,112.6,520.1,112.6z"/>
                <path className="st6" d="M560.6,80.6H555c-0.1-5.2-2.8-7.9-8.3-7.9c-5.2,0-8,2.7-8,6.8c0,2.1,0.6,3.7,1.9,4.7c1.3,0.9,3.7,2,7.4,2.9c10,2.8,14,6.2,14,12.8c0,7.8-6,12.7-14.6,12.7c-9.5,0-15.3-5.4-15.4-13.6h5.6c0.1,5.4,3.7,8.5,10,8.5c5.8,0,8.9-3,8.9-7.4c0-4.2-2.5-6.3-10.3-8.3c-8.9-2.3-13-5.4-13-12.2c0-6.9,5.2-12.1,13.7-12.1C555,67.6,560.5,72.6,560.6,80.6z"/>
                <path className="st6" d="M609,89.2v3h-34.3c0.4,9.4,6.6,15.4,15,15.4c6.8,0,11.2-2.7,13.2-8.1h5.6c-2.7,8.3-9.3,13.2-18.9,13.2c-5.9,0-10.8-2.1-14.7-6.2c-3.8-4.1-5.7-9.5-5.7-16.3c0-6.6,1.8-12,5.5-16.3c3.7-4.2,8.6-6.4,14.7-6.4C601.1,67.6,609,75.4,609,89.2z M574.7,87.3h28.8c0-9-5.5-14.6-14.1-14.6C581.1,72.7,575.3,78.7,574.7,87.3z"/>
            </svg>
        );
    } 

    render()
    {
        const {className} = this.props;
        const CA = ["MinPension"];

        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");
        
        return (
            <Link
                className={CS}
                href="https://minpension.se"
            >
                {this.Svg()}
            </Link>
        );
    }
}

MinPension.defaultProps =
{
    className: ""
};

export default MinPension;