import React from "react";
import "./terms.scss";
import Globals from "Class/Globals";
import {default as JsCookie} from "js-cookie";
import Checkbox from "Components/UI/Field/Checkbox";

class Terms extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Key = "cookiePreferences";
        this.state =
        {
            statistics: this.Statistics()
        };
    }

    ClearStatistics = () =>
    {
        const Cookies = document.cookie ? document.cookie.split('; ') : [];
        Cookies.forEach(cookie =>
        {
            if (!cookie.match(/^_pk_/))
            {
                return;
            }
            JsCookie.remove(cookie.replace(/=.*/g, ""));
        });
    }

    OnToggle = (e, statistics) =>
    {
        this.Statistics(statistics);
        this.setState({statistics});
    }

    Statistics = (set) =>
    {
        if (set !== undefined)
        {
            if (set === false)
            {
                this.ClearStatistics();
            }
            Globals.Storage(this.Key, JSON.stringify({statistics: set}));
            return set;
        }
        const {statistics} = Globals.StorageJson(this.Key, {});
        return statistics === undefined ? true : statistics;
    }

    render()
    {
        const {statistics} = this.state;
        return (
            <div className="Terms">
                <div className="TermsContent">
                    <h1 className="HeadingMain">Cookies</h1>
                    <p>Cookies är små textfiler, innehållandes bokstäver och siffror, som lagras på din dator eller enhet. Cookies sparas ner på din dator när du besöker en webbplats som använder cookies och kan användas för att spåra vilka sidor du besöker på webbplatsen, spara inmatad information eller komma ihåg dina preferenser.</p>
                    <h3>Vi använder cookies och liknande teknik för att:</h3>
                    <ul>
                        <li>Se till att vår tjänst fungerar.</li>
                        <li>Erbjuda en säker onlinemiljö med skydd mot bedrägeri och obehörig åtkomst</li>
                        <li>Ge dig en bättre och mer relevant upplevelse</li>
                        <li>Följa upp hur vår webbplats används</li>
                        <li>Följa upp webbplatsens prestanda</li>
                    </ul>
                    <p>Nedan kan du läsa hur du säger nej till cookies.</p>
                    <h3>Inställningar i webbläsaren:</h3>
                    <p>Du kan ange i webbläsarens inställningar om du vill tillåta eller inte tillåta cookies. Om du väljer att inte tillåta cookies kan du ändå använda våra webbsidor och läsa artiklar men du kan inte logga in och använda Min Pensionär.</p>
                    <div className="TermsSection">
                        <Checkbox
                            checked={true}
                            disabled={true}
                            text="Obligatoriska cookies"
                        />
                        <div className="TermsList">
                            <div className="TermsListTr">
                                <div className="TermsListTh">Namn</div>
                                <div className="TermsListTh">Cookienamn</div>
                                <div className="TermsListTh">Syfte</div>
                            </div>
                            <div className="TermsListTr">
                                <div className="TermsListTd">Spara inställningar för cookie</div>
                                <div className="TermsListTd">_minpensionar_cookies</div>
                                <div className="TermsListTd">Kommer ihåg vilka cookies du samtycker till i detta formulär.</div>
                            </div>
                        </div>
                    </div>
                    <div className="TermsSection">
                        <Checkbox
                            checked={statistics}
                            onChange={this.OnToggle}
                            text="Statistik"
                        />
                        <p>Dessa cookies används för att sammanställa statistik och hjälper oss att förbättra webbplatsen.</p>
                        <div className="TermsList">
                            <div className="TermsListTr">
                                <div className="TermsListTh">Namn</div>
                                <div className="TermsListTh">Cookienamn</div>
                                <div className="TermsListTh">Syfte</div>
                            </div>
                            <div className="TermsListTr">
                                <div className="TermsListTd">Matomo</div>
                                <div className="TermsListTd">_pk_id.x, _pk_ses.x</div>
                                <div className="TermsListTd">Används för statistik och logga generella användarmönster vilket ger oss möjlighet att förbättra tjänsten.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Terms;