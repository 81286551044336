
import React from "react";
import "./landing.scss";

import Calculator from "Class/Calculator";
import Button from "Components/UI/Button";
import Help from "Components/UI/Help";
import Link from "Components/UI/Link";
import LoadImage from "Components/Layout/LoadImage";
import SelectField from "Components/UI/Field/SelectField";

import Group from "Import/Images/group.png";
import Group2x from "Import/Images/group@2x.png";
import Pyramid from "Import/Images/pyramid.png";

class Landing extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            agesIn: false,
            agesOut: false,
            salaries: false
        };
    }

    componentDidMount()
    {
        this.SetScales();
    }

    SetScales = () =>
    {
        const {formatSalary, formatYear} = this.props;
        const {AgesIn, AgesOut, Salaries} = Calculator;
        const SetAgesIn = [];
        const SetAgesOut = [];
        const SetSalaries = [];

        for (let i = AgesIn[1]; i <= AgesIn[2]; i += AgesIn[0])
        {
            SetAgesIn[i] = formatYear(i);
        }

        for (let i = AgesOut[1]; i <= AgesOut[2]; i += AgesOut[0])
        {
            SetAgesOut[i] = formatYear(i);
        }

        for (let i = Salaries[1]; i <= Salaries[2]; i += Salaries[0])
        {
            SetSalaries[i] = formatSalary(i);
        }

        this.setState({
            agesIn: SetAgesIn,
            agesOut: SetAgesOut,
            salaries: SetSalaries
        });
    }

    render()
    {
        const
        {
            ageIn,
            ageOut,
            fullTime,
            hobbies,
            hobby,
            onClick,
            onUpdate,
            salary,
            servicePension
        } = this.props;

        const {
            agesIn,
            agesOut,
            salaries
        } = this.state;

        if (!agesIn || !agesOut || !salaries)
        {
            return "";
        }

        return (
            <div className="Landing">
                <LoadImage
                    className="LandingBackground"
                    src={Pyramid}
                />
                <div className="LandingContent Page White">
                    <div className="Content">
                        <h1 className="LandingHeading HeadingMain">Vem vill du bli när du blir pensionär?</h1>
                        <div className="LandingImageContainer">
                            <LoadImage
                                className="LandingImage"
                                src={Group}
                                src2x={Group2x}
                            />
                        </div>
                        <h3 className="LandingHeading HeadingSub">Hur tänker du dig ditt arbetsliv?<br/>Svara på några enkla frågor</h3>
                        <div className="LandingSelectFields">
                            <SelectField
                                id="ageIn"
                                label="Börjar jobba"
                                onChange={onUpdate}
                                options={agesIn}
                                placeholder="Välj ålder"
                                value={ageIn}
                            >
                                <Help className="LandingHelp">
                                    Den ålder då du börjar jobba på allvar, inte bara sommarjobb och extrajobb.
                                </Help>
                            </SelectField>
                            <SelectField
                                id="ageOut"
                                label="Pensionsålder"
                                onChange={onUpdate}
                                options={agesOut}
                                placeholder="Välj ålder"
                                value={ageOut}
                            >
                                <Help className="LandingHelp">
                                    Den ålder du skulle vilja sluta arbeta helt och gå i pension.
                                </Help>
                            </SelectField>
                            <SelectField
                                id="salary"
                                label="Förväntad månadslön"
                                onChange={onUpdate}
                                options={salaries}
                                placeholder="Välj lön"
                                value={salary}
                            >
                                <Help className="LandingHelp">
                                    Osäker på vad du kan förvänta dig i lön? <Link href="https://www.scb.se/hitta-statistik/sverige-i-siffror/lonesok/Search/?lon=sociolog">Kolla här</Link>
                                </Help>
                            </SelectField>
                            <SelectField
                                id="hobby"
                                label="Fritidsintresse"
                                onChange={onUpdate}
                                options={hobbies}
                                placeholder="Välj intresse"
                                value={hobby}
                            />
                            <SelectField
                                id="servicePension"
                                label="Tjänstepension"
                                onChange={onUpdate}
                                options={["Nej", "Ja"]}
                                value={servicePension}
                            >
                                <Help className="LandingHelp">
                                    De flesta arbetsgivare betalar dig extra pension, men det är inte självklart. Om du har eget företag har du ingen tjänstepension.
                                </Help>
                            </SelectField>
                            <SelectField
                                id="fullTime"
                                label="Anställningstyp"
                                onChange={onUpdate}
                                options={["Deltid", "Heltid"]}
                                value={fullTime}
                            />
                        </div>
                        <div className="LandingButtons">
                            <Button
                                disabled={ageIn < 0 || ageOut < 0 || hobby < 0 || salary < 0}
                                label="Visa min pension"
                                onClick={onClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Landing.defaultProps =
{
    ageIn: 0,
    ageOut: 0,
    formatSalary: () => {},
    formatYear: () => {},
    fullTime: 1,
    hobbies: [],
    hobby: 0,
    onClick: () => {},
    onUpdate: () => {},
    salary: 0,
    servicePension: 0
}

export default Landing;