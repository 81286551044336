
import Avatars from "Import/JSON/avatars.json";
import Data from "Import/JSON/data.json";

class Calculator
{
    constructor()
    {
        // The age the saving starts. [step, min, max]
        this.AgesIn = [1, 0, 100];
        // Retirement ages. [step, min, max]
        this.AgesOut = [1, 0, 100];
        this.Hobbies = [];
        this.HobbiesMap = {};
        // Salaries. [step, min, max]
        this.Salaries = [1000, 0, 100000];

        let AgeInIndex = 0;
        let AgeInLimit = Object.keys(Data).length - 1;

        // Extract options from the data object.
        for (let ageInStr in Data)
        {
            const AgeIn = parseInt(ageInStr, 10);
            let SalaryIndex = 0;
            let SalaryLimit = Object.keys(Data[ageInStr]).length - 1;

            if (AgeInIndex === AgeInLimit && AgeIn < this.AgesIn[2])
            {
                this.AgesIn[2] = AgeIn;
            }

            if (!AgeInIndex && AgeIn > this.AgesIn[1])
            {
                this.AgesIn[1] = AgeIn;
            }

            for (let salaryStr in Data[ageInStr])
            {
                const Salary = parseInt(salaryStr, 10) * 1000;

                if (Salary < 19000)
                {
                    SalaryLimit--;
                    continue;
                }

                let AgeOutIndex = 0;
                let AgeOutLimit = Object.keys(Data[ageInStr][salaryStr][0]).length - 1;

                if (SalaryIndex === SalaryLimit && Salary < this.Salaries[2])
                {
                    this.Salaries[2] = Salary;
                }

                if (!SalaryIndex && Salary > this.Salaries[1])
                {
                    this.Salaries[1] = Salary;
                }

                for (let ageOutStr in Data[ageInStr][salaryStr][0])
                {
                    const AgeOut = parseInt(ageOutStr, 10);

                    if (AgeOutIndex === AgeOutLimit && AgeOut < this.AgesOut[2])
                    {
                        this.AgesOut[2] = AgeOut
                    }

                    if (!AgeOutIndex && AgeOut > this.AgesOut[1])
                    {
                        this.AgesOut[1] = AgeOut;
                    }

                    AgeOutIndex++;
                }

                SalaryIndex++
            }

            AgeInIndex++;
        }

        // Extract, map and sort hobbies.
        for (let key in Avatars)
        {
            Avatars[key].hobbies.forEach(([slug, hobby, name, text]) => {
                this.Hobbies.push(hobby);
                this.HobbiesMap[hobby] = [slug, key, name, text];
            });
        };

        this.Hobbies.sort();
    }

    Avatar = (ageIn, ageOut, salary, hobby = 0, servicePension = true, fullTime = true, interactionParam = "") =>
    {
        const Hobby = this.Hobbies[hobby];
        const [Slug, Key, Name, Text] = this.HobbiesMap[Hobby] || [];
        const Avatar = Avatars[Key];

        if (!Avatar || !Key)
        {
            return false;
        }

        const Texts = [].concat(Avatar.texts, Avatars.Default.texts);
        const T1 = [];
        const T2 = [];
        const T3 = [];
        const T4 = [];

        Texts.forEach(({conditions, image, text}) => {
            let Match = true;
            let HasParam = false;

            conditions.forEach(([param, comparator, compareValue]) => {
                if (param === interactionParam)
                {
                    HasParam = true;
                }

                if (!Match)
                {
                    return;
                }

                let Value;

                switch (param)
                {
                    case "ageIn":
                        Value = ageIn;
                        break;
                    case "ageOut":
                        Value = ageOut;
                        break;
                    case "salary":
                        Value = salary;
                        break;
                    case "servicePension":
                        Value = servicePension ? 1 : 0;
                        break;
                    case "fullTime":
                        Value = fullTime ? 1 : 0;
                        break;
                    default:
                        Value = 0;
                }

                switch (comparator)
                {
                    case "<":
                        Match = Value < compareValue;
                        break;
                    case "<=":
                        Match = Value <= compareValue;
                        break;
                    case "==":
                    case "===":
                        Match = Value === compareValue;
                        break;
                    case "!=":
                    case "!==":
                        Match = Value !== compareValue;
                        break;
                    case ">=":
                        Match = Value >= compareValue;
                        break;
                    case ">":
                        Match = Value > compareValue;
                        break;
                    default:
                        Match = false;
                }
            });

            if (Match && HasParam)
            {
                T1.push(text);
                T3.push(image);
            }
            else if (Match)
            {
                T2.push(text);
                T4.push(image);
            }
        });

        return {
            bubblePosition: Avatar.bubble,
            hobby: Hobby,
            key: Key,
            name: Name,
            slug: Slug,
            text: Text,
            tips: T1.concat(T2),
            tipsImages: T3.concat(T4)
        };
    }

    Calculate = (ageIn, ageOut, salary, servicePension = true, fullTime = true) =>
    {
        const AgeInStr = String(ageIn);
        const AgeOutStr = String(ageOut);
        const SalaryStr = String(Math.round(salary / 1000));

        if (Data[AgeInStr] === undefined || Data[AgeInStr][SalaryStr] === undefined)
        {
            return 0;
        }

        if (!fullTime)
        {
            return this.Calculate(ageIn, ageOut, Data[AgeInStr][SalaryStr][2] * 1000, servicePension, true);
        }

        if (!servicePension)
        {
            return Data[AgeInStr][SalaryStr][1][AgeOutStr] || 0
        }

        return Data[AgeInStr][SalaryStr][0][AgeOutStr] || 0;
    }
}

export default new Calculator();