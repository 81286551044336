
/*!
 *  Internal/external link.
 *
 *  @param string className - Append a class name.
 *  @param string href - External URL.
 *  @param string label - Link label.
 *  @param function onClick - Callback for when the item is clicked.
 *  @param object style - Style attributes.
 *  @param string target - Optional. Link target window when a href has been specified.
 *  @param string title - The item title tag.
 *  @param string to - URI path when used for internal navigation.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./link.scss";

import {NavLink as RouterLink, withRouter} from "react-router-dom";

class Link extends React.Component
{
    OnKey = (e) =>
    {
        const {onClick} = this.props;

        switch (e.which)
        {
            case 13:
                onClick(e);
                break;

            default:
                return
        }

        e.stopPropagation();
        e.preventDefault();
    }

    render()
    {

        let {children, className, href, label, onClick, style, target, title, to} = this.props;
        const CA = ["Link"];

        if (href.match(/^\s*javascript/i))
        {
            href = target = "";
        }
        else if (href.match(/^\s*mailto/i))
        {
            target = "";
        }

        if (className)
        {
            CA.push(className);
        }

        const CS = CA.join(" ");

        if (href)
        {
            return (
                <a
                    rel="noopener noreferrer"
                    className={CS}
                    href={href}
                    onClick={onClick}
                    style={style}
                    target={target}
                    title={title}
                >
                    {label || children}
                </a>
            );
        }
        else if (to)
        {
            return (
                <RouterLink
                    activeClassName="ActiveLink"
                    className={CS}
                    exact={true}
                    onClick={onClick}
                    style={style}
                    title={title}
                    to={to}
                >
                    {label || children}
                </RouterLink>
            );
        }
        else
        {
            return (
                <div
                    className={CS}
                    onClick={onClick}
                    onKeyDown={this.OnKey}
                    style={style}
                    tabIndex="0"
                    title={title}
                >
                    {label || children}
                </div>
            );
        }
    }
}

Link.propTypes =
{
    className: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    target: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string
};

Link.defaultProps =
{
    className: "",
    href: "",
    label: "",
    onClick: () => {},
    style: {},
    target: "_blank",
    title: "",
    to: ""
};

export default withRouter(Link);